<style lang='less'>
.account-info-show-vue {
  .account-image {
    .h-avatar {
      width: 100px;
      margin:0 auto;
    }
  }
  .account-name {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0,0,0,.85);
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .account-desc {
    text-align: center;
    width: 200px;
    margin: 0 auto;
  }
  .account-detail-desc {
    margin-top: 40px;
    p {
      margin-bottom: 10px;
      > span {
        margin-left: 10px;
      }
    }
  }
  .divider {
    border-top: 1px dashed #e8e8e8;
    display: block;
    height: 0px;
    width: 100%;
    margin: 24px 0;
    clear: both;
  }
  .account-detail-tags-title {
    font-weight: 500;
    color: rgba(0,0,0,.85);
    margin-bottom: 12px;
  }
  .h-taginput > span {
    margin-bottom: 5px;
  }
}
</style>
<template>
  <div class="h-panel account-info-show-vue">
    <div class="h-panel-body">
      <p class="account-image">
        <Avatar :src="account.img" :width="100"></Avatar>
      </p>
      <p class="account-name">{{account.user}}</p>
      <p class="account-desc"><pre>富在术数，不在劳身；利在势居，不在力耕。</pre></p>
      <div class="account-detail-desc">
        <p><i class="icon-mail"></i><span>10086@qq.com</span></p>
        <p><i class="icon-briefcase"></i><span>真诚是必杀技</span></p>
        <p><i class="icon-location-2"></i><span>对接请联系上级索要对接代码</span></p>
      </div>
      <div class="divider"></div>
      <div>
        <p class="account-detail-tags-title">我的标签</p>
        <p>
          <TagInput v-model="tags" style="width: 100%" :readonly="true"></TagInput>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Object
  },
  data() {
    return {
		tags:[
			"一掷千金",
			"风流倜傥"
		]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    }
  },
  computed: {
    account: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
};
</script>
