<style lang="less">
.account-info-vue {
  padding: 30px 30px 30px 0;
  max-width: 500px;
}
</style>
<template>
  <div class="account-info-vue">
    <Form :model="acc" :rules="rules" ref="form" showErrorTip>
      <FormItem label="头像" prop="avatar">
        <Upload display-type="image" data-type="url" v-model="acc.img" />
      </FormItem>
      <FormItem label="账号" prop="user">
        <Input type="text" v-model="acc.user" disabled/>
      </FormItem>
	  <FormItem label="饼干" prop="user">
	    <Input type="text" v-model="acc.money" disabled/>
	  </FormItem>
	  <FormItem label="Token" prop="token">
	    <Input type="text" v-model="acc.key" disabled/>
	  </FormItem>
<!--      <FormItem label="描述" prop="desc">
        <Textarea v-autosize v-model="acc.desc" />
      </FormItem> -->
      <FormItem label="密码">
        <Input type="text" v-model="password" placeholder="留空为不修改密码"/>
      </FormItem>
      <FormItem label="" prop="location">
        <Button color="primary" @click="save2" :loading="saveloading">保存</Button>
        <Button @click="reset">重置</Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import utils from '@common/utils';
import Request from '@common/request';
import { message,notice } from 'heyui';
export default {
  props: {
    account: Object
  },
  data() {
    return {
      acc: utils.copy(this.account),
      rules: {
        required: ['name', 'email', 'org'],
        email: ['email']
      },
	  password:'',
      saveloading: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
	save2() {
		if(this.password==''){
			return;
		}
		Request.User.pass({pass:this.password}).then(res=>{
			if(res.code==1){
				message.success(res.msg);
			}else{
				message.error(res.msg);
			}
		});
	},
    save() {
      if (!this.$refs.form.valid().result) return;
      this.saveloading = true;
      setTimeout(() => {
        this.saveloading = false;
        this.$store.dispatch('updateAccount', utils.copy(this.acc));
      }, 1000);
    },
    reset() {
      this.$refs.form.resetValid();
      this.acc = utils.copy(this.account);
    }
  },
  computed: {}
};
</script>
